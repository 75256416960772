define("discourse/plugins/chat/discourse/components/chat-retention-reminder", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/plugins/chat/discourse/components/chat-retention-reminder-text", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _ajax, _ajaxError, _chatRetentionReminderText, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatRetentionReminder extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    get show() {
      return this.args.channel?.isDirectMessageChannel && this.currentUser?.get("needs_dm_retention_reminder") || this.args.channel?.isCategoryChannel && this.currentUser?.get("needs_channel_retention_reminder");
    }
    async dismiss() {
      try {
        await (0, _ajax.ajax)("/chat/dismiss-retention-reminder", {
          method: "POST",
          data: {
            chatable_type: this.args.channel.chatableType
          }
        });
        const field1 = this.args.channel.isDirectMessageChannel ? "needs_dm_retention_reminder" : "needs_channel_retention_reminder";
        this.currentUser.set(field1, false);
      } catch (e1) {
        (0, _ajaxError.popupAjaxError)(e1);
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "dismiss", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.show}}
          <div class="chat-retention-reminder">
            <ChatRetentionReminderText @channel={{@channel}} />
            <DButton
              @action={{this.dismiss}}
              @icon="times"
              class="no-text btn-icon btn-transparent dismiss-btn"
            />
          </div>
        {{/if}}
      
    */
    {
      "id": "jsU4U5Wj",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"show\"]],[[[1,\"      \"],[10,0],[14,0,\"chat-retention-reminder\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@channel\"],[[30,1]]],null],[1,\"\\n        \"],[8,[32,1],[[24,0,\"no-text btn-icon btn-transparent dismiss-btn\"]],[[\"@action\",\"@icon\"],[[30,0,[\"dismiss\"]],\"times\"]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@channel\"],false,[\"if\"]]",
      "moduleName": "/home/pacs/hsh10/users/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-retention-reminder.js",
      "scope": () => [_chatRetentionReminderText.default, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatRetentionReminder;
});