define("discourse/plugins/chat/discourse/components/chat/composer/button", ["exports", "discourse-common/helpers/d-icon", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _dIcon, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ChatComposerButton = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="chat-composer-button__wrapper">
      <button type="button" class="chat-composer-button" ...attributes>
        {{dIcon @icon}}
      </button>
    </div>
  
  */
  {
    "id": "Uix+wHZm",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"chat-composer-button__wrapper\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,4,\"button\"],[24,0,\"chat-composer-button\"],[17,1],[12],[1,\"\\n      \"],[1,[28,[32,0],[[30,2]],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"&attrs\",\"@icon\"],false,[]]",
    "moduleName": "/home/pacs/hsh10/users/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/composer/button.js",
    "scope": () => [_dIcon.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = ChatComposerButton;
});