define("discourse/plugins/chat/discourse/components/chat-thread-participants", ["exports", "@glimmer/component", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat-user-avatar", "@ember/component", "@ember/template-factory"], function (_exports, _component, _discourseI18n, _chatUserAvatar, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatThreadParticipants extends _component.default {
    get showParticipants() {
      if (!this.args.thread) {
        return;
      }
      if (this.includeOriginalMessageUser) {
        return this.participantsUsers.length > 1;
      }
      return this.participantsUsers.length > 0;
    }
    get includeOriginalMessageUser() {
      return this.args.includeOriginalMessageUser ?? true;
    }
    get participantsUsers() {
      const users1 = this.args.thread.preview.participantUsers;
      if (this.includeOriginalMessageUser) {
        if (users1.length > 3) {
          return users1.slice(0, 2).concat(users1[users1.length - 1]);
        } else {
          return users1;
        }
      }
      return users1.filter(user1 => {
        return user1.id !== this.args.thread.originalMessage.user.id;
      });
    }
    get otherCountLabel() {
      return _discourseI18n.default.t("chat.thread.participants_other_count", {
        count: this.args.thread.preview.otherParticipantCount
      });
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.showParticipants}}
          <div class="chat-thread-participants" ...attributes>
            <div class="chat-thread-participants__avatar-group">
              {{#each this.participantsUsers as |user|}}
                <ChatUserAvatar
                  @user={{user}}
                  @avatarSize="tiny"
                  @showPresence={{false}}
                  @interactive={{false}}
                />
              {{/each}}
            </div>
            {{#if @thread.preview.otherParticipantCount}}
              <div class="chat-thread-participants__other-count">
                {{this.otherCountLabel}}
              </div>
            {{/if}}
          </div>
        {{/if}}
      
    */
    {
      "id": "ct6Uf05Q",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"showParticipants\"]],[[[1,\"      \"],[11,0],[24,0,\"chat-thread-participants\"],[17,1],[12],[1,\"\\n        \"],[10,0],[14,0,\"chat-thread-participants__avatar-group\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,0,[\"participantsUsers\"]]],null]],null],null,[[[1,\"            \"],[8,[32,0],null,[[\"@user\",\"@avatarSize\",\"@showPresence\",\"@interactive\"],[[30,2],\"tiny\",false,false]],null],[1,\"\\n\"]],[2]],null],[1,\"        \"],[13],[1,\"\\n\"],[41,[30,3,[\"preview\",\"otherParticipantCount\"]],[[[1,\"          \"],[10,0],[14,0,\"chat-thread-participants__other-count\"],[12],[1,\"\\n            \"],[1,[30,0,[\"otherCountLabel\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"&attrs\",\"user\",\"@thread\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/home/pacs/hsh10/users/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-thread-participants.js",
      "scope": () => [_chatUserAvatar.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatThreadParticipants;
});