define("discourse/plugins/chat/discourse/components/chat-notices", ["exports", "@glimmer/component", "@ember/service", "discourse/plugins/chat/discourse/components/chat-notice", "discourse/plugins/chat/discourse/components/chat-retention-reminder", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _chatNotice, _chatRetentionReminder, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatNotices extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "noticesManager", [(0, _service.service)("chat-channel-notices-manager")]))();
    #noticesManager = (() => (dt7948.i(this, "noticesManager"), void 0))();
    get noticesForChannel() {
      return this.noticesManager.notices.filter(notice1 => notice1.channelId === this.args.channel.id);
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="chat-notices">
          <ChatRetentionReminder @channel={{@channel}} />
    
          {{#each this.noticesForChannel as |notice|}}
            <ChatNotice @notice={{notice}} @channel={{@channel}} />
          {{/each}}
        </div>
      
    */
    {
      "id": "VfosDSaa",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-notices\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@channel\"],[[30,1]]],null],[1,\"\\n\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"noticesForChannel\"]]],null]],null],null,[[[1,\"        \"],[8,[32,1],null,[[\"@notice\",\"@channel\"],[[30,2],[30,1]]],null],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@channel\",\"notice\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/home/pacs/hsh10/users/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-notices.js",
      "scope": () => [_chatRetentionReminder.default, _chatNotice.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatNotices;
});