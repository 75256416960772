define("discourse/plugins/chat/discourse/components/chat/thread-list/header", ["exports", "@glimmer/component", "@ember/service", "@ember/template", "discourse/helpers/replace-emoji", "discourse-common/helpers/i18n", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/navbar", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _template2, _replaceEmoji, _i18n, _discourseI18n, _navbar, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatThreadListHeader extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    threadListTitle = (() => _discourseI18n.default.t("chat.threads.list"))();
    get title() {
      let title1 = (0, _replaceEmoji.default)(this.threadListTitle);
      if (this.site.mobileView) {
        title1 += " - " + (0, _replaceEmoji.default)(this.args.channel.title);
      }
      return (0, _template2.htmlSafe)(title1);
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <Navbar as |navbar|>
          {{#if this.site.mobileView}}
            <navbar.BackButton
              @route="chat.channel"
              @routeModels={{@channel.routeModels}}
              @title={{i18n "chat.return_to_channel"}}
            />
          {{/if}}
    
          <navbar.Title @title={{this.title}} @icon="discourse-threads" />
    
          <navbar.Actions as |action|>
            <action.CloseThreadsButton @channel={{@channel}} />
          </navbar.Actions>
        </Navbar>
      
    */
    {
      "id": "+q39eXRf",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"site\",\"mobileView\"]],[[[1,\"        \"],[8,[30,1,[\"BackButton\"]],null,[[\"@route\",\"@routeModels\",\"@title\"],[\"chat.channel\",[30,2,[\"routeModels\"]],[28,[32,1],[\"chat.return_to_channel\"],null]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[8,[30,1,[\"Title\"]],null,[[\"@title\",\"@icon\"],[[30,0,[\"title\"]],\"discourse-threads\"]],null],[1,\"\\n\\n      \"],[8,[30,1,[\"Actions\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,3,[\"CloseThreadsButton\"]],null,[[\"@channel\"],[[30,2]]],null],[1,\"\\n      \"]],[3]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n  \"]],[\"navbar\",\"@channel\",\"action\"],false,[\"if\"]]",
      "moduleName": "/home/pacs/hsh10/users/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/thread-list/header.js",
      "scope": () => [_navbar.default, _i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatThreadListHeader;
});