define("discourse/plugins/chat/discourse/components/chat-header", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/routing", "@ember/service", "discourse-common/helpers/d-icon", "discourse-common/lib/get-url", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _routing, _service, _dIcon, _getUrl, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatHeader extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "previousURL", [_tracking.tracked]))();
    #previousURL = (() => (dt7948.i(this, "previousURL"), void 0))();
    title = (() => _discourseI18n.default.t("chat.back_to_forum"))();
    heading = (() => _discourseI18n.default.t("chat.heading"))();
    constructor() {
      super(...arguments);
      this.router.on("routeDidChange", this, this.#updatePreviousURL);
    }
    get shouldRender() {
      return this.siteSettings.chat_enabled && this.site.mobileView && this.isChatOpen;
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.router.off("routeDidChange", this, this.#updatePreviousURL);
    }
    get isChatOpen() {
      return this.router.currentURL.startsWith("/chat");
    }
    get forumLink() {
      return (0, _getUrl.default)(this.previousURL ?? this.router.rootURL);
    }
    #updatePreviousURL() {
      if (!this.isChatOpen) {
        this.previousURL = this.router.currentURL;
      }
    }
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.shouldRender}}
          <div class="c-header">
            <a
              href={{this.forumLink}}
              class="btn-flat back-to-forum"
              title={{this.title}}
            >
              {{icon "arrow-left"}}
              {{this.title}}
            </a>
    
            <LinkTo @route="chat.index" class="c-heading">{{this.heading}}</LinkTo>
          </div>
        {{else}}
          {{yield}}
        {{/if}}
      
    */
    {
      "id": "xiX91PMq",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldRender\"]],[[[1,\"      \"],[10,0],[14,0,\"c-header\"],[12],[1,\"\\n        \"],[10,3],[15,6,[30,0,[\"forumLink\"]]],[14,0,\"btn-flat back-to-forum\"],[15,\"title\",[30,0,[\"title\"]]],[12],[1,\"\\n          \"],[1,[28,[32,0],[\"arrow-left\"],null]],[1,\"\\n          \"],[1,[30,0,[\"title\"]]],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[8,[32,1],[[24,0,\"c-heading\"]],[[\"@route\"],[\"chat.index\"]],[[\"default\"],[[[[1,[30,0,[\"heading\"]]]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[18,1,null],[1,\"\\n\"]],[]]],[1,\"  \"]],[\"&default\"],false,[\"if\",\"yield\"]]",
      "moduleName": "/home/pacs/hsh10/users/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-header.js",
      "scope": () => [_dIcon.default, _routing.LinkTo],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatHeader;
});