define("discourse/plugins/chat/discourse/components/chat/navbar/channel-title", ["exports", "@ember/routing", "discourse/plugins/chat/discourse/components/channel-title", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _routing, _channelTitle, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ChatNavbarChannelTitle = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{#if @channel}}
      <LinkTo
        @route="chat.channel.info.settings"
        @models={{@channel.routeModels}}
        class="c-navbar__channel-title"
      >
        <ChannelTitle @channel={{@channel}} />
      </LinkTo>
    {{/if}}
  
  */
  {
    "id": "175ljfkY",
    "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[8,[32,0],[[24,0,\"c-navbar__channel-title\"]],[[\"@route\",\"@models\"],[\"chat.channel.info.settings\",[30,1,[\"routeModels\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[32,1],null,[[\"@channel\"],[[30,1]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@channel\"],false,[\"if\"]]",
    "moduleName": "/home/pacs/hsh10/users/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/navbar/channel-title.js",
    "scope": () => [_routing.LinkTo, _channelTitle.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = ChatNavbarChannelTitle;
});